import { createAction, handleActions } from 'redux-actions';
import { ISettings } from '../../typings/api';
import { IFailurePayload } from '../../typings/boilerplate';
import { IReduxState } from '../reducers';
import {TAPIRequest} from '../api';

export interface IAppSettings {
  loading: boolean;
  error: boolean;
  settings?: ISettings;
}

const initialState: IAppSettings = {
  loading: true,
  error: false,
  settings: null
};

export const CONSTANTS = {
  REQUEST: 'settings-getSettings/REQUEST',
  SUCCESS: 'settings-getSettings/SUCCESS',
  FAILURE: 'settings-getSettings/FAILURE'
};

export const request = createAction(CONSTANTS.REQUEST);
export const failure = createAction(CONSTANTS.FAILURE, (payload: IFailurePayload) => payload);
export const success = createAction(CONSTANTS.SUCCESS);

export const getSettings: any = () => {
  return async (
    dispatch: (action: any) => void,
    _: () => IReduxState,
    { requestAPI }: { requestAPI: TAPIRequest; }
  ) => {
    dispatch(request());

    try {
      const { data } = await requestAPI({ method: 'GET', url: '/settings' });
      dispatch(success(data));
      return true;
    } catch (err) {
      console.log(err);
      dispatch(
        failure({ statusCode: err?.response && err?.response?.status })
      );
      return false;
    }
  };
};

const updateUserPasswordReducer = handleActions<IAppSettings, any>(
  {
    [CONSTANTS.REQUEST]: (state: IAppSettings) => ({
      ...state,
      loading: true,
      error: false,
      settings: null

    }),
    [CONSTANTS.SUCCESS]: (state: IAppSettings, { payload }: { payload: ISettings }) => ({
      ...state,
      settings: payload,
      loading: false,
      error: false
    }),
    [CONSTANTS.FAILURE]: (state: IAppSettings) => ({
      ...state,
      loading: false,
      error: true,
      settings: null
    })
  },
  initialState
);

export default updateUserPasswordReducer;
