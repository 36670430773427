import 'nprogress/nprogress.css';
import { css } from 'styled-components';
import theme from '../../styles/theme/theme';

export default css`
  #nprogress {
    .bar {
      z-index: 1202;
      top: 0;
      background-color: ${theme.palette.secondary.main};
    }

    .spinner {
      z-index: 1202;
      top: 20px;
    }

    .spinner-icon {
        border-top-color: ${theme.palette.secondary.main};
        border-left-color: ${theme.palette.secondary.main};
      }
  }
`;
