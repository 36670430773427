import profileReducer, { IProfileState } from './profile';
import updatePasswordReducer from './updatePassword';
import { combineReducers } from 'redux';
import { IUpdateState } from '../../typings/api';

export interface IUserState {
  profile: IProfileState;
  updatePassword: IUpdateState;
}

export default combineReducers({
  profile: profileReducer,
  updatePassword: updatePasswordReducer
});
