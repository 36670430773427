import 'moment/locale/fr';
import withRedux from 'next-redux-wrapper';
import App, { AppProps, Container, AppInitialProps, AppContext } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import theme from '../styles/theme/theme';
import { ThemeProvider } from '@material-ui/styles';
import { getActiveRoute } from '../server/routes';
import { initStore } from '../store';
import { GlobalStyle } from '../styles/theme';
import nextConfig from 'next/config';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

interface IAppProps extends AppInitialProps, AppProps {
  store: Store;
  originalUrl: string;
  asPath: string;
}

const { appTitle } = nextConfig().publicRuntimeConfig;

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
});

export default withRedux(initStore)(
  class MyApp extends App<IAppProps> {
    static async getInitialProps({ Component, ctx }: AppContext) {
      return {
        asPath: ctx.asPath,
        pageProps: Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
        originalUrl: ctx.req && (ctx as any).req.originalUrl
      };
    }

    render() {
      const { Component, pageProps, store, originalUrl, asPath } = this.props;
      const activeRouteKey = getActiveRoute(asPath);
      const getLayout = (Component as any).getLayout || ((page: React.ReactNode) => page);

      return (
        <Container>
          <Head>
            <title>{appTitle}</title>
          </Head>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <>
                  <GlobalStyle />
                  <CssBaseline />
                  <div className={'main'}>
                    {getLayout(
                      <Component {...pageProps} originalUrl={originalUrl} />,
                      activeRouteKey
                    )}
                  </div>
                </>
              </ThemeProvider>
            </QueryClientProvider>
          </Provider>
        </Container>
      );
    }
  }
);
