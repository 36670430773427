import { createGlobalStyle } from 'styled-components';
import reboot from 'styled-reboot';
import nProgress from '../../components/shared/NProgress.style';
// WARNING : do not import css in a specific page. It will break its navigation.

const rebootCSS = reboot({});

// @ts-ignore
export const GlobalStyle = createGlobalStyle`
  ${rebootCSS};
  ${nProgress};

  .MuiTooltip-tooltip {
    font-size: 14px!important;;
  }

  .MuiBreadcrumbs-ol {
  list-style-type: none!important;
  }

 .MuiPickersModal-dialogRoot {
  .MuiPickersModal-withAdditionalAction {
    .MuiButton-root {
      background-color: white;
    }
  }
 }

  .ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }
  }

  .bubble-menu {
    display: flex;
    background-color: #0D0D0D;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
      border: none;
      background: none;
      color: #FFF;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0 0.2rem;
      opacity: 0.6;

      &:hover,
      &.is-active {
        opacity: 1;
      }
    }
  }

  .floating-menu {
    display: flex;
    background-color: #0D0D0D10;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
      border: none;
      background: none;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0 0.2rem;
      opacity: 0.6;

      &:hover,
      &.is-active {
        opacity: 1;
      }
    }
  }

  // .main {
  //   height: 100vh;
  // }
`;
