import {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import { createAction, handleActions } from 'redux-actions';
import { IProfile, IUpdateState } from '../../typings/api';
import { IFailurePayload } from '../../typings/boilerplate';
import { DEBUG_AUTH } from '../api';
import { logout } from '../auth';
import {parseCookies} from 'nookies';
import {NextPageContext} from 'next';

export interface IProfileState extends IUpdateState {
  profile: IProfile | null;
}

const initialState: IProfileState = {
  loading: false,
  error: false,
  profile: null
};

export const CONSTANTS = {
  REQUEST: 'user-profile/REQUEST',
  SUCCESS: 'user-profile/SUCCESS',
  FAILURE: 'user-profile/FAILURE'
};

export const request = createAction(CONSTANTS.REQUEST);
export const failure = createAction(CONSTANTS.FAILURE, (payload: IFailurePayload) => payload);
export const success = createAction(CONSTANTS.SUCCESS, (payload: IProfile) => payload);

export const getProfile: any = () => {
  return async (dispatch: (action: any) => void, _: any, { api, ctx }: { api: AxiosInstance; ctx: NextPageContext; }) => {
    dispatch(request());
    DEBUG_AUTH && console.info('Fetching profile...');
    return api.get('/users/self', { headers: { Authorization: parseCookies(ctx || null).access_token } })
    .then((response: AxiosResponse) => {
      const { data } = response;
      DEBUG_AUTH && console.info('Fetch profile sucess', data.role);
      if (['admin', 'partner', 'influencer'].indexOf(data.role) === -1) {
        dispatch(logout());
        return Promise.reject();
      }
      dispatch(success(data));
      return Promise.resolve(true);
    }).catch((error: AxiosError) => {
      if (!error.response) {
        DEBUG_AUTH && console.log('Error while getting profile (not from network)', error);
      }
      DEBUG_AUTH && console.info('Error while getting profile (from network)', error.response);
      const { response } = error;
      dispatch(failure({ statusCode: response && response.status }));
    });
  };
};

const profileReducer = handleActions<IProfileState, any>(
  {
    [CONSTANTS.REQUEST]: (state: IProfileState) => ({
      ...state,
      loading: true,
      error: false,
      profile: null
    }),
    [CONSTANTS.SUCCESS]: (state: IProfileState, { payload }: { payload: IProfile }) => ({
      ...state,
      loading: false,
      error: false,
      profile: payload
    }),
    [CONSTANTS.FAILURE]: (state: IProfileState) => ({
      ...state,
      loading: false,
      error: true,
      profile: null
    })
  },
  initialState
);

export default profileReducer;
