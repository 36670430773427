import { Action, combineReducers, Reducer } from 'redux';

import authReducer, { CONSTANTS as AUTH_CONSTANTS, IAuthState } from './auth';
import userReducer, { IUserState } from './user';
import settingsrReducer, { ISettingsState } from './settings';

export interface IReduxState {
  auth: IAuthState;
  user: IUserState;
  settings: ISettingsState;
}

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  settings: settingsrReducer
});

const rootReducer: Reducer = (state: IReduxState, action: Action<any>) => {
  // Make sure to reset the whole store when logging out
  if (action.type === AUTH_CONSTANTS.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action as any);
};

export default rootReducer;
