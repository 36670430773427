import { NextPageContext } from 'next';
import { parseCookies } from 'nookies';
import { applyMiddleware, createStore, compose, StoreEnhancer, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import loggerMiddleware from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import getConfig from 'next/config';

import { isClient } from '../utils/deviceDetection';
import api, { requestAPI, configureInterceptors, authInstance, apiV2AuthInstance } from './api';
import reducers from './reducers';
import { setStoreInstance } from '../utils/api';
const { publicRuntimeConfig } = getConfig();

let store: Store;

export const initStore: any = (initialState: any, ctx: NextPageContext) => {
  let accessToken;
  if (ctx.req && !initialState) {
    // On the server, let's get the accessToken from the request and set connected in redux accordingly
    // so the redux is properly rehydrated before anything is mounted
    const cookies = parseCookies(ctx);
    accessToken = cookies[publicRuntimeConfig.reactAppAccessTokenPath];
    initialState = {
      auth: {
        connected: !!accessToken
      }
    };
  }

  const middlewares = [
    thunkMiddleware.withExtraArgument({
      requestAPI: requestAPI(accessToken),
      ctx,
      api,
      apiAuth: authInstance,
      apiV2Auth: apiV2AuthInstance
    })
  ];

  const LOG_REDUX_ON_SERVER = false;
  if (process.env.NODE_ENV === 'development' && (isClient || LOG_REDUX_ON_SERVER)) {
    middlewares.push(loggerMiddleware);
  }

  const appliedMiddleware = applyMiddleware(...middlewares);
  const composeFunction: (middlewares: StoreEnhancer) => any =
    process.env.REACT_APP_ENV !== 'production' ? composeWithDevTools : compose;

  store = createStore(reducers, initialState, composeFunction(appliedMiddleware));
  setStoreInstance(store);
  configureInterceptors(store, ctx);
  return store;
};

export function getStoreInstance(): Store {
  return store;
}
