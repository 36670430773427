import { createAction, handleActions } from 'redux-actions';
import { IUpdateState } from '../../typings/api';
import { IFailurePayload } from '../../typings/boilerplate';
import { IReduxState } from '../reducers';
import { AxiosInstance } from 'axios';

export interface IUpdateUserPasswordProps {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}

const initialState: IUpdateState = {
  loading: false,
  error: false
};

export const CONSTANTS = {
  REQUEST: 'user-updateUserPassword/REQUEST',
  SUCCESS: 'user-updateUserPassword/SUCCESS',
  FAILURE: 'user-updateUserPassword/FAILURE'
};

export const request = createAction(CONSTANTS.REQUEST);
export const failure = createAction(CONSTANTS.FAILURE, (payload: IFailurePayload) => payload);
export const success = createAction(CONSTANTS.SUCCESS);

export const update: any = (data: IUpdateUserPasswordProps) => {
  return async (
    dispatch: (action: any) => void,
    _: () => IReduxState,
    { apiAuth }: { apiAuth: AxiosInstance; }
  ) => {
    dispatch(request());

    try {
      await apiAuth.put('/users/password', data);
      dispatch(success());
      return true;
    } catch (err) {
      console.log(err);
      dispatch(
        failure({ statusCode: err?.response && err?.response?.status })
      );
      return false;
    }
  };
};

const updateUserPasswordReducer = handleActions<IUpdateState, any>(
  {
    [CONSTANTS.REQUEST]: (state: IUpdateState) => ({
      ...state,
      loading: true,
      error: false
    }),
    [CONSTANTS.SUCCESS]: (state: IUpdateState) => ({
      ...state,
      loading: false,
      error: false
    }),
    [CONSTANTS.FAILURE]: (state: IUpdateState) => ({
      ...state,
      loading: false,
      error: true
    })
  },
  initialState
);

export default updateUserPasswordReducer;
